import { graphql, useStaticQuery } from 'gatsby';
import { Fade } from 'react-awesome-reveal';
import React, { Fragment, useState, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

import {
	Hero,
	MetaData,
	NewsArticle,
	NewsArticleBig,
	NewsCard,
	TitleSection,
} from '../components';
import Button from '../components/Button';
import ThreeCol from '../components/ThreeCol';
import useNews from '../utils/hooks/useNews';
import breakpoints from '../styles/breakpoints';

const News = () => {
	const { allNews } = useNews();

	const {
		news: { title, acf },
	} = useStaticQuery(graphql`
		query NewsPage {
			news: wordpressPage(slug: { eq: "news" }) {
				title
				acf {
					news_header {
						header_title
						header_logo {
							source_url
						}
						header_link_popup
						header_link
						header_link_text
						header_images {
							image {
								source_url
							}
							focus
						}
					}
				}
			}
		}
	`);

	let [articleLimit, setArticleLimit] = useState(10);

	const isMobile = useMediaQuery({
		query: breakpoints.mobileLand,
	});

	if (!allNews || !allNews.length) return null;

	const filteredNews = allNews.filter(
		(item) =>
			!['3208', 'join-our-team-in-bognor-regis'].includes(item.node.slug)
	);

	const firstPost = useMemo(() => {
		if (!filteredNews.length) return null;
		return filteredNews[0];
	}, [filteredNews]);

	let posts = useMemo(
		() => filteredNews.slice(isMobile ? 0 : 1, articleLimit),
		[filteredNews, articleLimit, isMobile]
	);

	return (
		<Fragment>
			<MetaData title={title} />

			<Hero
				title={acf.news_header.header_title || title}
				images={acf.news_header.header_images}
				logo={
					acf.news_header.header_logo && acf.news_header.header_logo.source_url
				}
				link={acf.news_header.header_link}
				linkText={acf.news_header.header_link_text}
				linkPopup={acf.news_header.header_link_popup}
			/>

			<TitleSection
				content={
					<Fragment>
						{firstPost && !isMobile && (
							<Fade>
								<NewsArticleBig {...firstPost.node} />
							</Fade>
						)}

						<ThreeCol>
							{posts.map(({ node }, i) => {
								return (
									<Fade key={node.slug}>
										<NewsCard
											image={
												node.acf.header_image &&
												node.acf.header_image.source_url
											}
											title={node.title}
											excerpt={node.excerpt}
											slug={node.slug}
										/>
									</Fade>
								);
							})}
						</ThreeCol>

						{articleLimit < allNews.length && (
							<div className="w-row" style={{ marginTop: '50px' }}>
								<div className="w-col w-col-12">
									<Button
										onClick={() => {
											setArticleLimit(articleLimit + 6);
										}}
										className={'load-more-btn'}
										// disabled={newsLoading}
									>
										Load more
									</Button>
								</div>
							</div>
						)}
					</Fragment>
				}
			/>
		</Fragment>
	);
};

export default News;
