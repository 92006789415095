import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

type NewsItem = GatsbyTypes.AllNewsQuery['news']['edges'][0];

const useNews = () => {
	const { news } = useStaticQuery<GatsbyTypes.AllNewsQuery>(graphql`
		query AllNews {
			news: allWordpressWpNews {
				totalCount
				edges {
					node {
						wordpress_id
						title
						slug
						categories {
							wordpress_id
							name
							slug
						}
						excerpt
						content
						acf {
							header_image {
								source_url
								alt_text
							}
							meta_title
							meta_description
						}
					}
				}
			}
		}
	`);

	const findOne = ({
		slug,
		wordpress_id,
	}: {
		slug?: string;
		wordpress_id?: number;
	}): NewsItem | undefined => {
		return news.edges.find(({ node }) => {
			if (slug) return node.slug === slug;
			if (wordpress_id) return node.wordpress_id === wordpress_id;
			return null;
		});
	};

	const findAllByCategorySlug = (slug: string): NewsItem | undefined => {
		return news.edges.filter(({ node }) => {
			return node.categories?.find((item) => item.slug === slug);
		});
	};

	return {
		allNews: news.edges,
		findOne,
		findAllByCategorySlug,
	} as const;
};

export default useNews;
